import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import './MobileNav.css';
import defaultProfile1 from '../../assets/default-profile-1.png';

const MobileNav = ({ userProfile, totalNotifications }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const activeSection = new URLSearchParams(location.search).get('section') || 'home';

  const handleProfileClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <nav className="mobile-nav">
        <Link to="/dashboard?section=home" className={`nav-item ${activeSection === 'home' ? 'active' : ''}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="nav-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
          <span>Home</span>
        </Link>

        <Link to="/dashboard?section=orders" className={`nav-item ${activeSection === 'orders' ? 'active' : ''}`}>
          <div className="nav-item-content">
            <svg xmlns="http://www.w3.org/2000/svg" className="nav-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>
            {totalNotifications > 0 && 
              <span className="mobile-notification-badge">
                {totalNotifications}
              </span>
            }
          </div>
          <span>Orders</span>
        </Link>

        <Link to="/dashboard?section=theme" className={`nav-item ${activeSection === 'theme' ? 'active' : ''}`}>
          <svg viewBox="0 0 24 24" className="nav-icon" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" />
            <line x1="7" y1="7" x2="7.01" y2="7" />
          </svg>
          <span>Theme</span>
        </Link>

        <button onClick={() => setIsPreviewOpen(true)} className="floating-preview-button">
          <svg xmlns="http://www.w3.org/2000/svg" className="preview-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
        </button>

        <div className="profile-menu-container">
          <button onClick={handleProfileClick} className="nav-item">
            <img 
              src={userProfile?.profileImage || defaultProfile1} 
              alt={userProfile?.displayName || 'Profile'} 
              className="nav-icon profile-icon"
            />
            <span>{userProfile?.displayName || 'Profile'}</span>
          </button>

          {showProfileMenu && (
            <div className="profile-dropdown-menu">
              <Link to="/dashboard?section=settings" className="dropdown-item">
                Settings
              </Link>
              <Link to="/dashboard?section=payments" className="dropdown-item">
                Payment Setup
              </Link>
              <button onClick={handleSignOut} className="dropdown-item logout-button">
                Logout
              </button>
            </div>
          )}
        </div>
      </nav>

      {isPreviewOpen && (
        <div className="preview-modal">
          <div className="preview-modal-header">
            <button className="close-button" onClick={() => setIsPreviewOpen(false)}>
              ×
            </button>
          </div>
          <div className="preview-modal-content">
            <iframe 
              src={`/${userProfile?.username}`}
              title="Store Preview"
              className="preview-iframe"
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
