import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Homepage.css';
import homeImage from '../images/home-image.png';
import socialMediaImage from '../images/social-media.png';
import brandsImage from '../images/brands.png';
import aiPriceImage from '../images/ai-price.png';
import calculatorImage from '../images/calculator.png';
import cardImage from '../images/card.png';
import estimatedReachImage from '../images/estimated-reach.png';
import kaijuLogo from '../images/kaiju.png';
import { FaEnvelope, FaLinkedin } from 'react-icons/fa';

function Homepage() {
  const [currentSection, setCurrentSection] = useState('home');
  const [changingText, setChangingText] = useState('stories');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  
  const textsArray = useMemo(() => [
    'Insta stories', 'Instagram posts', 'TikTok videos', 
    'YouTube vids', 'Twitch streams', 'Twitter threads'
  ], []);

  const sectionRefs = useRef({
    home: createRef(),
    about: createRef(),
    contact: createRef(),
    platforms: createRef(),
    match: createRef(),
    aiPricing: createRef(),
    earnings: createRef(),
    card: createRef(),
    estimatedReach: createRef(),
    claimEarly: createRef()
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
            const section = entry.target.getAttribute('data-section');
            if (section) {
              setCurrentSection(section);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '-20% 0px',
        threshold: [0.1, 0.5],
      }
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      section.style.opacity = '0';
      section.style.transform = 'translateY(20px)';
      section.style.transition = 'opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1)';
      const sectionId = Object.keys(sectionRefs.current).find(
        key => sectionRefs.current[key].current === section
      );
      if (sectionId) {
        section.setAttribute('data-section', sectionId);
      }
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const imageObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('image-visible');
            entry.target.classList.remove('image-hidden');
          }
        });
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 0.1,
      }
    );

    const images = document.querySelectorAll('[class*="-image"]');
    images.forEach((image) => {
      image.classList.add('image-hidden');
      imageObserver.observe(image);
    });

    return () => imageObserver.disconnect();
  }, []);

  useEffect(() => {
    if (currentSection && sectionRefs.current[currentSection]?.current) {
      sectionRefs.current[currentSection].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentSection]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setChangingText(prevText => {
        const currentIndex = textsArray.indexOf(prevText);
        return textsArray[(currentIndex + 1) % textsArray.length];
      });
    }, 1500);

    return () => clearInterval(intervalId);
  }, [textsArray]);

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    
    buttons.forEach(button => {
      const particleContainer = document.createElement('div');
      particleContainer.className = 'button-particles';
      button.appendChild(particleContainer);
      
      button.addEventListener('mousemove', (e) => {
        const rect = button.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        
        const deltaX = (x - centerX) / centerX;
        const deltaY = (y - centerY) / centerY;
        
        const tiltX = deltaY * 10;
        const tiltY = -deltaX * 10;
        
        button.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale(1.05)`;
      });
      
      button.addEventListener('mouseleave', () => {
        button.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) scale(1)';
      });
      
      button.addEventListener('mouseenter', () => {
        for (let i = 0; i < 8; i++) {
          createParticle(button);
        }
      });
    });
    
    function createParticle(button) {
      const particle = document.createElement('span');
      particle.className = 'particle';
      
      const x = Math.random() * button.offsetWidth;
      const y = Math.random() * button.offsetHeight;
      const tx = (Math.random() - 0.5) * 100;
      const ty = (Math.random() - 0.5) * 100;
      
      particle.style.setProperty('--tx', `${tx}px`);
      particle.style.setProperty('--ty', `${ty}px`);
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;
      
      const particleContainer = button.querySelector('.button-particles');
      particleContainer.appendChild(particle);
      
      particle.addEventListener('animationend', () => {
        particle.remove();
      });
    }
  }, []);

  const handleClaim = () => {
    navigate('/signup');
  };
  
  const handleJoinNow = () => {
    navigate('/signup');
  };

  return (
    <div className="page-wrapper">
      <div className="header-logo animate-fade-in">
        <img src={kaijuLogo} alt="Kaiju Logo" />
      </div>

      {/* Hero Section */}
      <div className="homepage-container">
        <div className="content-wrapper">
          <div className="left-content">
            <div className="creator-badge animate-slide-down">
            ✨ Made by creators, for creators ✨
            </div>
            <h1 className="homepage-title animate-slide-up">
              Get paid for your<br />
              <span className="changing-text">{changingText}</span>.
            </h1>
            <p className="homepage-subtitle animate-slide-up-delay">
              The easiest way to charge for your content.<br />
              Add a link to your bio, set your rates with AI, and get paid. <br />
              It's that simple!
            </p>
            <div className="homepage-action-wrapper animate-slide-up-delay-2">
              <div className="input-button-group">
                <span className="input-prefix">kaiju.bio/</span>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="yourname"
                  className="homepage-input"
                />
                <button onClick={handleClaim} className="homepage-button">
                  Claim
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content">
          <img 
            src={homeImage} 
            alt="Creator Platform" 
            className="homepage-image animate-fade-in" 
          />
        </div>
        <Link 
          to="/login" 
          className="login-button"
        >
          Log in
        </Link>
      </div>

      {/* Platforms Section - White BG */}
      <div ref={sectionRefs.current.platforms} className="platforms-container animate-section">
        <div className="platforms-content">
          <h1 className="platforms-title">Connect with your audience</h1>
          <p className="platforms-subtitle">
            Share your custom Kaiju link from your Instagram, TikTok, Twitch, Youtube... 
            And any platform you already use
          </p>
          <img src={socialMediaImage} alt="Social Media Platforms" className="platforms-image floating-animation" />
        </div>
      </div>

      {/* Match Section - Purple BG */}
      <div ref={sectionRefs.current.match} className="match-container animate-section">
        <div className="match-content-wrapper">
          <div className="match-left-content">
            <img src={brandsImage} alt="Brand Matching" className="match-image floating-animation" />
          </div>
          <div className="match-right-content">
            <h1 className="match-title">It's a match! 😍</h1>
            <p className="match-subtitle">
              We will match you with brands you already love or find brands that want to 
              pay you for your audience. 
            </p>
            <button onClick={handleJoinNow} className="match-button">
              Join Now
            </button>
          </div>
        </div>
      </div>

      {/* AI Pricing Section - White BG */}
      <div ref={sectionRefs.current.aiPricing} className="ai-pricing-container animate-section">
        <div className="ai-pricing-content-wrapper">
          <div className="ai-pricing-left-content">
            <h1 className="ai-pricing-title">AI Price Suggestions 🤩</h1>
            <p className="ai-pricing-subtitle">
              Don't know how much to charge for your content? <br />
              We got you!
            </p>
            <button onClick={handleJoinNow} className="ai-pricing-button">
              Join Now
            </button>
          </div>
          <div className="ai-pricing-right-content">
            <img src={aiPriceImage} alt="AI Pricing" className="ai-pricing-image floating-animation" />
          </div>
        </div>
      </div>

      {/* Earnings Section */}
      <div ref={sectionRefs.current.earnings} className="earnings-container">
        <div className="earnings-content">
          <img 
            src={calculatorImage} 
            alt="Earnings Calculator" 
            className="earnings-image"
          />
          <h2 className="earnings-title">Calculate your earnings</h2>
          <p className="earnings-subtitle">
            Use our AI-powered calculator to estimate your potential earnings based on your content and audience.
          </p>
          <button onClick={handleClaim} className="earnings-button">Try the Calculator</button>
        </div>
      </div>

      {/* Kaiju Card Section - White BG */}
      <div ref={sectionRefs.current.card} className="ai-pricing-container animate-section">
        <div className="ai-pricing-content-wrapper">
          <div className="ai-pricing-left-content">
            <h1 className="ai-pricing-title">Get Your Kaiju Card 💳</h1>
            <p className="ai-pricing-subtitle">
              Access your earnings instantly with the Kaiju debit card. <br />
              Spend your creator money anywhere, anytime!
            </p>
            <button onClick={handleJoinNow} className="ai-pricing-button">
              Join Waitlist
            </button>
          </div>
          <div className="ai-pricing-right-content">
            <img src={cardImage} alt="Kaiju Card" className="ai-pricing-image floating-animation" />
          </div>
        </div>
      </div>

      {/* Estimated Reach Section - Purple BG */}
      <div ref={sectionRefs.current.estimatedReach} className="estimated-reach-container animate-section">
        <div className="estimated-reach-content-wrapper">
          <div className="estimated-reach-left-content">
            <h1 className="estimated-reach-title">Know what you pay for 🥳</h1>
            <p className="estimated-reach-subtitle">
              Know exactly what you get for your money. <br />
              Estimated reach, with views, likes, comments and more...
            </p>
            <button onClick={handleJoinNow} className="estimated-reach-button">
              Start Now
            </button>
          </div>
          <div className="estimated-reach-right-content">
            <img src={estimatedReachImage} alt="Estimated Reach Analytics" className="estimated-reach-image floating-animation" />
          </div>
        </div>
      </div>

      {/* Claim Early Section - White BG */}
      <div ref={sectionRefs.current.claimEarly} className="claim-early-container animate-section">
        <div className="claim-early-content">
          <h1 className="claim-early-title">Claim Your Spot Early!🫡 </h1>
          <p className="claim-early-subtitle">
            Join the waitlist for early access and secure your name before it's gone! 
          </p>
          <button onClick={handleClaim} className="claim-early-button pulse-animation">
            Claim your name
          </button>
        </div>
      </div>

      {/* Footer */}
      <div className="footer-container">
        <img src={kaijuLogo} alt="Kaiju Logo" className="footer-logo" />
        <div className="footer-links">
          <a 
            href="mailto:hi@kaiju.bio" 
            className="footer-link" 
            aria-label="Email us"
          >
            <FaEnvelope />
          </a>
          <a 
            href="https://www.linkedin.com/company/kaiju-bio" 
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit our LinkedIn"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Homepage;