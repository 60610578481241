import instagramIcon from '../assets/instagram.png';
import twitterIcon from '../assets/twitter.png';
import tiktokIcon from '../assets/tiktok.png';
import youtubeIcon from '../assets/youtube.png';
import twitchIcon from '../assets/twitch.png';
import linkedinIcon from '../assets/linkedin.png';

export const socialMediaIcons = {
  instagram: instagramIcon,
  twitter: twitterIcon,
  tiktok: tiktokIcon,
  youtube: youtubeIcon,
  twitch: twitchIcon,
  linkedin: linkedinIcon,
};

export const getSocialMediaLink = (platform, handle) => {
  switch (platform) {
    case 'instagram':
      return `https://www.instagram.com/${handle}`;
    case 'twitter':
      return `https://twitter.com/${handle}`;
    case 'tiktok':
      return `https://www.tiktok.com/@${handle}`;
    case 'youtube':
      return `https://www.youtube.com/@${handle}`;
    case 'twitch':
      return `https://www.twitch.tv/${handle}`;
    case 'linkedin':
      return `https://www.linkedin.com/in/${handle}`;
    default:
      return '#';
  }
};
