import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { signOut, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './Dashboard.css';
import './dashboard/settings.css';
import './dashboard/userProfile.css';
import { themes } from '../themes/themes';
import { auth, db, storage, functions } from '../firebase';
import defaultProfile1 from '../assets/default-profile-1.png';
import OrdersList from './dashboard/OrdersList';
import PurchasesSection from './dashboard/PurchasesSection';
import CreatorOffersSection from './dashboard/CreatorOffersSection';
import PaymentMethodsSection from './dashboard/PaymentMethodsSection';
import { debounce } from 'lodash';
import { ClipLoader } from 'react-spinners';
import PreviewContainer from './dashboard/PreviewContainer';
import MobileNav from './dashboard/MobileNav';
import FAQ from './FAQ';
import FeedbackModal from './settings/FeedbackModal';
import { calculateSuggestedRates, calculateAverages, calculateEngagementRate } from '../utils/instagramUtils';
import { checkReservedUsername } from '../utils/reservedUsernames';
import FirstOfferCelebration from './FirstOfferCelebration';


const contentTypes = [
  // Instagram
  { id: 1, name: 'Instagram Post', type: 1 },
  { id: 2, name: 'Instagram Story', type: 'story' },
  { id: 3, name: 'Instagram Reel', type: 2 },
  { id: 4, name: 'Instagram Carousel Post', type: 8 },
  // TikTok
  { id: 5, name: 'TikTok Video', type: 'tiktok_video' },
  // YouTube
  { id: 6, name: 'YouTube Video', type: 'youtube_video' },
  { id: 7, name: 'YouTube Short', type: 'youtube_short' },
];

const moreContentTypes = [
  { id: 8, name: 'Tweet/Retweet', type: 'tweet_retweet' },
  { id: 9, name: 'LinkedIn Post', type: 'linkedin_post' },
  { id: 10, name: 'Shoutout', type: 'shoutout' },
  { id: 11, name: 'Sound Promo', type: 'sound_promo' },
  { id: 12, name: 'Podcast Promo', type: 'podcast_promo' },
  { id: 13, name: 'Email List Promo', type: 'email_list_promo' },
  { id: 14, name: 'Duet', type: 'duet' },
  { id: 15, name: 'Gifting', type: 'gifting' },
  { id: 16, name: 'Review', type: 'review' },
  { id: 17, name: 'UGC', type: 'ugc' },
  { id: 18, name: 'Affiliate', type: 'affiliate' }
];

const BIO_MAX_LENGTH = 100;
const USERNAME_MIN_LENGTH = 3;
const USERNAME_MAX_LENGTH = 30;

function Dashboard() {
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({
    displayName: '',
    bio: '',
    profileImage: '',
    socialMedia: {},
    username: '',
    email: '',
    stripeOnboardingComplete: false,
    stripeAccountId: null,
    instagramFollowers: null,
    lastInstagramFetch: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [sales, setSales] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = React.useRef(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [suggestedPrices, setSuggestedPrices] = useState({});
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [editedDisplayName, setEditedDisplayName] = useState('');
  const [editedBio, setEditedBio] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [declineReason, setDeclineReason] = useState('');
  const [orderToDecline, setOrderToDecline] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [showUsernameChangeModal, setShowUsernameChangeModal] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [usernameAvailabilityMessage, setUsernameAvailabilityMessage] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [passwordResetStatus, setPasswordResetStatus] = useState({ message: '', isError: false });
  const [showFaq, setShowFaq] = useState(false);
  const [showFirstOfferCelebration, setShowFirstOfferCelebration] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [creatorOffers, setCreatorOffers] = useState([]);
  const [originalProfile, setOriginalProfile] = useState({
    displayName: '',
    bio: '',
    username: '',
  });
  const [originalUsername, setOriginalUsername] = useState('');
  const [originalCreatorOffers, setOriginalCreatorOffers] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('default');
  const [showSaveCheckmark, setShowSaveCheckmark] = useState(false);
  const [isSavingTheme, setIsSavingTheme] = useState(false);

  // Add a ref to track if we've loaded the initial data
  const initialDataLoaded = React.useRef(false);

  // Username availability check function
  const checkUsernameAvailability = useCallback(async (username) => {
    if (!username || username.length < USERNAME_MIN_LENGTH) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage(username.length > 0 ? `Username must be at least ${USERNAME_MIN_LENGTH} characters` : '');
      return;
    }

    if (username.length > USERNAME_MAX_LENGTH) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage(`Username must be ${USERNAME_MAX_LENGTH} characters or less`);
      return;
    }

    // Check format
    if (!/^[a-z0-9-]+$/.test(username)) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage('Username can only contain lowercase letters, numbers, and hyphens');
      return;
    }

    // Check if starts or ends with hyphen
    if (username.startsWith('-') || username.endsWith('-')) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage('Username cannot start or end with a hyphen');
      return;
    }

    // Check for consecutive hyphens
    if (username.includes('--')) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage('Username cannot contain consecutive hyphens');
      return;
    }

    try {
      // Check reserved usernames
      const reservedCheck = checkReservedUsername(username);
      if (reservedCheck.isReserved) {
        setIsUsernameAvailable(false);
        setUsernameAvailabilityMessage(reservedCheck.message);
        return;
      }

      // Check if taken by another user
      const docRef = doc(db, "usernames", username.toLowerCase());
      const docSnap = await getDoc(docRef);
      const available = !docSnap.exists();
      setIsUsernameAvailable(available);
      setUsernameAvailabilityMessage(available ? 'Username is available!' : 'This username is already taken');
    } catch (error) {
      console.error("Error checking username:", error);
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage('Error checking username availability');
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      // Only fetch data if we haven't loaded it yet
      if (!initialDataLoaded.current) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            
            // Set all states in one batch to minimize re-renders
            const updates = {
              userProfile: {
                ...userData,
                profileImage: userData.profileImage || defaultProfile1,
                socialMedia: userData.socialMedia || {},
                email: user.email,
                username: userData.username || '',
                stripeOnboardingComplete: userData.stripeOnboardingComplete || false,
                stripeAccountId: userData.stripeAccountId || null
              },
              originalProfile: {
                displayName: userData.displayName || '',
                bio: userData.bio || '',
                username: userData.username || '',
              },
              creatorOffers: Array.isArray(userData.creatorOffers) 
                ? userData.creatorOffers.filter(offer => offer && offer.name)
                : [],
            };

            setUserProfile(updates.userProfile);
            setSelectedTheme(userData.selectedTheme || 'default');
            setOriginalProfile(updates.originalProfile);
            setOriginalUsername(userData.username || '');
            setCreatorOffers(updates.creatorOffers);
            setOriginalCreatorOffers(updates.creatorOffers);
            setEditedDisplayName(userData.displayName || '');
            setEditedBio(userData.bio || '');
            setNewUsername(userData.username || '');

            // Calculate suggested prices if needed
            if (userData.instagramStats) {
              const suggestedRates = calculateSuggestedRates(userData.instagramStats);
              setSuggestedPrices(suggestedRates);
            }

            initialDataLoaded.current = true;
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
          setError('Failed to load user data. Please try again.');
        }
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        await Promise.all([
          fetchOrders(),
          fetchSales()
        ]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    
    // Reset FAQ state when section changes
    if (section !== 'faq') {
      setShowFaq(false);
    }

    if (section) {
      setActiveSection(section);
    } else {
      // Default to 'home' if no section is specified
      setActiveSection('home');
      // Update URL to reflect default section
      navigate('/dashboard?section=home', { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (userProfile.socialMedia?.instagram?.handle) {
      fetchInstagramData(userProfile.socialMedia.instagram.handle);
    }
  }, [userProfile.socialMedia?.instagram?.handle]);

  useEffect(() => {
    if (newUsername === originalUsername) {
      setIsUsernameAvailable(false);
      setUsernameAvailabilityMessage('');
      return;
    }

    const timeoutId = setTimeout(() => {
      checkUsernameAvailability(newUsername);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [newUsername, originalUsername, checkUsernameAvailability]);

  useEffect(() => {
    if (showFirstOfferCelebration) {
      const timer = setTimeout(() => {
        setShowFirstOfferCelebration(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showFirstOfferCelebration]);

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setShowWelcomeModal(true);
      localStorage.setItem('hasSeenWelcome', 'true');
    }
  }, []);

  useEffect(() => {
    // Track changes for save button
    const hasProfileChanges = 
      editedDisplayName !== originalProfile.displayName ||
      editedBio !== originalProfile.bio ||
      newUsername !== originalUsername;

    const hasOfferChanges = JSON.stringify(creatorOffers) !== JSON.stringify(originalCreatorOffers);

    setUnsavedChanges(hasProfileChanges || hasOfferChanges);
  }, [editedDisplayName, editedBio, newUsername, creatorOffers, originalProfile, originalUsername, originalCreatorOffers]);

  // eslint-disable-next-line no-unused-vars
  const debouncedProfileUpdate = useCallback((updates) => {
    const updateProfileDebounced = debounce(async () => {
      try {
        await updateDoc(doc(db, "users", auth.currentUser.uid), updates);
        setSuccess('Profile updated successfully!');
      } catch (error) {
        setError('Failed to update profile. Please try again.');
      }
    }, 1000);
    updateProfileDebounced();
  }, []);

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      // Update creator offers
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        creatorOffers: creatorOffers
      });
      
      // Store the current offers as original to track future changes
      setOriginalCreatorOffers(creatorOffers);
      
      // If profile was edited, update those changes too
      if (isEditingProfile) {
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          displayName: editedDisplayName,
          bio: editedBio,
          username: newUsername || userProfile.username
        });
        
        // Update original profile state
        setOriginalProfile({
          displayName: editedDisplayName,
          bio: editedBio,
          username: newUsername || userProfile.username
        });
        setOriginalUsername(newUsername || userProfile.username);
      }
      
      setUnsavedChanges(false);
      setSuccess("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      setError("Failed to save changes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const fetchOrders = async () => {
    try {
      const ordersQuery = query(
        collection(db, "orders"),
        where("sellerId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(ordersQuery);
      const fetchedOrders = [];
      let totalNotifications = 0;

      querySnapshot.forEach((doc) => {
        const orderData = { id: doc.id, ...doc.data() };
        fetchedOrders.push(orderData);
        // Count both pending and payment_received orders that haven't been viewed
        if ((orderData.status === 'pending' || orderData.status === 'payment_received') && !orderData.viewed) {
          totalNotifications++;
        }
      });

      setOrders(fetchedOrders);
      setTotalNotifications(totalNotifications);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setError("Failed to fetch orders");
    }
  };

  const fetchSales = async () => {
    try {
      const salesRef = collection(db, 'sales');
      const q = query(salesRef, where("sellerId", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      const salesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSales(salesData);
    } catch (error) {
      console.error("Error fetching sales:", error);
      setError("Failed to load sales data.");
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const storageRef = ref(storage, `profile_images/${auth.currentUser.uid}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUserProfile(prev => ({ ...prev, profileImage: downloadURL }));
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          profileImage: downloadURL
        });
        setSuccess("Profile image updated successfully!");
      } catch (error) {
        console.error("Error updating profile image:", error);
        setError("Failed to update profile image. Please try again.");
      }
    }
  };

  const handleAcceptOrder = async (orderId) => {
    setIsSaving(true);
    setError(null);
    
    if (!userProfile.stripeAccountId) {
      setError({
        type: 'stripe-warning',
        message: 'Please set up your payment account first to accept orders.'
      });
      setIsSaving(false);
      return;
    }

    try {
      const acceptRequest = httpsCallable(functions, 'acceptRequest');
      const result = await acceptRequest({ orderId });

      if (result.data.success) {
        // Update local state immediately
        setOrders(prevOrders => 
          prevOrders.map(order => 
            order.id === orderId 
              ? { ...order, status: 'accepted', viewed: true } 
              : order
          )
        );
        
        // Update notification count
        setTotalNotifications(prev => Math.max(0, prev - 1));
        
        return Promise.resolve(result.data);
      } else {
        throw new Error(result.data.error || 'Failed to accept the request');
      }
    } catch (err) {
      console.error('Error accepting order:', err);
      setError(err.message || 'Error accepting the order');
      return Promise.reject(err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeclineOrder = async (orderId, reason) => {
    try {
      const declineRequest = httpsCallable(functions, 'declineRequest');
      const result = await declineRequest({ 
        orderId,
        reason: reason || ''
      });

      if (result.data.success) {
        setOrders(prevOrders => 
          prevOrders.map(order => 
            order.id === orderId 
              ? { ...order, status: 'declined' } 
              : order
          )
        );
        return Promise.resolve();
      } else {
        throw new Error(result.data.error || 'Failed to decline the request');
      }
    } catch (error) {
      console.error('Error declining order:', error);
      return Promise.reject(error);
    }
  };

  const handleDeclineClick = (orderId) => {
    setOrderToDecline(orderId);
    setShowFaq(true);
  };

  const handleDeclineSubmit = async () => {
    if (!declineReason.trim()) {
      setError("Please provide a reason for declining the order.");
      return;
    }

    try {
      const declineRequest = httpsCallable(functions, 'declineRequest');
      const result = await declineRequest({ 
        orderId: orderToDecline,
        reason: declineReason 
      });

      if (result.data.success) {
        await fetchOrders();
        setSuccess("Order declined");
        setShowFaq(false);
        setDeclineReason('');
        setOrderToDecline(null);
      } else {
        throw new Error(result.data.error || 'Failed to decline the request');
      }
    } catch (err) {
      console.error('Error declining order:', err);
      setError(err.message || 'Error declining the order');
    }
  };

  const handleOfferSelect = (offer) => {
    setCreatorOffers(prev => {
      // Check if offer with same name already exists
      if (prev.some(o => o.name === offer.name)) {
        return prev;
      }
      return [...prev, { ...offer, active: true }];  // Ensure active is true for new offers
    });
  };

  const handlePriceChange = (contentName, changes) => {
    setCreatorOffers(prev => {
      const updatedOffers = changes.updatedOffers || prev.map(offer => {
        if (offer.name === contentName) {
          return {
            ...offer,
            ...changes,
            active: changes.active !== undefined ? changes.active : (offer.active ?? true) // Maintain active state, default to true
          };
        }
        return offer;
      });
      return updatedOffers;
    });
    setUnsavedChanges(true);
  };

  const handleRemove = (offerName) => {
    setCreatorOffers((prevOffers) =>
      prevOffers.filter((offer) => offer.name !== offerName)
    );
    setUnsavedChanges(true);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
      setError("Failed to sign out. Please try again.");
    }
  };

  const handleProfileClick = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLogoutMenu && !event.target.closest('.user-profile-desktop')) {
        setShowLogoutMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLogoutMenu]);

  const handleEditProfileToggle = () => {
    setIsEditingProfile(true);
    setEditedDisplayName(userProfile.displayName || '');
    setEditedBio(userProfile.bio || '');
  };

  const handleSaveProfile = async () => {
    setIsSaving(true);
    try {
      const updatedFields = {
        displayName: editedDisplayName,
        bio: editedBio,
        username: userProfile.username, // Keep the existing username
        socialMedia: userProfile.socialMedia || {}, // Keep existing social media
        creatorOffers: creatorOffers // Keep existing creator offers
      };

      const updateProfile = httpsCallable(functions, 'updateProfile');
      const result = await updateProfile(updatedFields);

      if (result.data && result.data.success) {
        // Update local state
        setUserProfile(prev => ({
          ...prev,
          displayName: editedDisplayName,
          bio: editedBio
        }));
        setIsEditingProfile(false);
        setSuccess("Profile updated successfully!");
        setTimeout(() => setSuccess(null), 3000);
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setError(error.message || "Failed to update profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleUsernameChangeRequest = async () => {
    if (!isUsernameAvailable) {
      return;
    }

    setIsSaving(true);
    try {
      // Call the cloud function to update username
      const updateUsernameFunction = httpsCallable(functions, 'updateUsername');
      const result = await updateUsernameFunction({ username: newUsername });

      if (result.data && result.data.success) {
        // Update local state
        setUserProfile(prev => ({ ...prev, username: newUsername }));
        setOriginalUsername(newUsername);
        
        // Close modal and reset states
        setShowUsernameChangeModal(false);
        setNewUsername('');
        
        // Show success message
        setSuccess(`Username successfully changed to ${newUsername}`);
        setTimeout(() => setSuccess(''), 3000);
      } else {
        throw new Error(result.data.error || "Failed to update username");
      }
    } catch (error) {
      console.error("Error updating username:", error);
      setError(error.message || "Failed to update username. Please try again.");
      setShowUsernameChangeModal(true);
    } finally {
      setIsSaving(false);
    }
  };

  const fetchInstagramData = async (username) => {
    try {
      const functions = getFunctions();
      const fetchInstagramDataFunction = httpsCallable(functions, 'fetchInstagramData');
      const fetchRecentPostsFunction = httpsCallable(functions, 'fetchRecentPosts');

      // Fetch follower count
      const followerResult = await fetchInstagramDataFunction({ username });
      if (followerResult.data.error) {
        console.warn('Warning fetching follower count:', followerResult.data.error);
      }
      const followerCount = followerResult.data.followerCount || 0;

      // Fetch recent posts
      const postsResult = await fetchRecentPostsFunction({ username });
      if (postsResult.data.error) {
        console.warn('Warning fetching posts:', postsResult.data.error);
      }

      const posts = postsResult.data.posts || [];
      const processedPosts = posts.map(post => ({
        ...post,
        estimatedViews: post.mediaType === 2 ? (post.playCount || 0) : Math.round((post.likeCount || 0) / 0.03)
      }));

      // Calculate metrics
      const calculatedAverages = calculateAverages(processedPosts);
      const engagement = calculateEngagementRate(processedPosts, followerCount);
      
      // Calculate suggested rates
      const rates = calculateSuggestedRates(
        calculatedAverages.globalAverageViews || 0,
        calculatedAverages.reelViews || 0,
        calculatedAverages.carouselViews || 0,
        calculatedAverages.singlePhotoViews || 0,
        followerCount,
        engagement
      );

      // Update suggested prices
      setSuggestedPrices(rates);

      // Update creator offers with suggested prices if they don't have prices set
      setCreatorOffers(prevOffers => 
        prevOffers.map(offer => ({
          ...offer,
          price: offer.price || rates[offer.name]?.min || ''
        }))
      );

      // Update user profile with follower count
      setUserProfile(prev => ({
        ...prev,
        instagramFollowers: followerCount,
        lastInstagramFetch: Date.now()
      }));

      // Update Firestore with the new data
      const userRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userRef, {
        instagramFollowers: followerCount,
        lastInstagramFetch: Date.now()
      });

    } catch (error) {
      console.error('Error fetching Instagram data:', error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, auth.currentUser.email);
      setPasswordResetStatus({
        message: 'Password reset email sent! Please check your inbox.',
        isError: false
      });
    } catch (error) {
      setPasswordResetStatus({
        message: 'Failed to send reset email. Please try again.',
        isError: true
      });
    }
  };

  const handleCompleteOrder = async (orderId, completionMessage) => {
    setIsSaving(true);
    setError(null);
    
    if (!userProfile.stripeAccountId) {
      setError({
        type: 'stripe-warning',
        message: 'Please set up your payment account first to complete orders.'
      });
      setIsSaving(false);
      return;
    }

    try {
      const completeOrder = httpsCallable(functions, 'completeOrder');
      const result = await completeOrder({ orderId, completionMessage });

      if (result.data.success) {
        // Update local state immediately
        setOrders(prevOrders => 
          prevOrders.map(order => 
            order.id === orderId 
              ? { ...order, status: 'completed' } 
              : order
          )
        );
        return Promise.resolve(result.data);
      } else {
        throw new Error(result.data.error || 'Failed to complete the order');
      }
    } catch (err) {
      console.error('Error completing order:', err);
      setError(err.message || 'Error completing the order');
      return Promise.reject(err);
    } finally {
      setIsSaving(false);
    }
  };

  const [showShareMenu, setShowShareMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.share-section')) {
        setShowShareMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCopyUrl = () => {
    const url = `beta.kaiju.bio/${userProfile.username}`;
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
      setShowShareMenu(false);
    });
  };

  const handleOpenUrl = () => {
    window.open(`https://beta.kaiju.bio/${userProfile.username}`, '_blank');
    setShowShareMenu(false);
  };

  const handleShare = async () => {
    const shareData = {
      title: `${userProfile.displayName || userProfile.username}'s Kaiju`,
      text: 'Check out my Kaiju page!',
      url: `https://beta.kaiju.bio/${userProfile.username}`
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        handleCopyUrl();
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
    setShowShareMenu(false);
  };

  const renderSettingsSection = () => (
    <div className="settings-section">
      <h2>My Account</h2>
      <div className="username-display settings-button">
        <span className="username-text">beta.kaiju.bio/{userProfile.username}</span>
        <button className="text-button" onClick={() => setShowUsernameChangeModal(true)}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
          Change username
        </button>
      </div>

      <button className="settings-button" onClick={handlePasswordReset}>
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
          <path d="M7 11V7a5 5 0 0 1 10 0v4" />
        </svg>
        Change Password
      </button>
      {passwordResetStatus.message && (
        <div className={`password-reset-status ${passwordResetStatus.isError ? 'error' : 'success'}`}>
          {passwordResetStatus.message}
        </div>
      )}
      <h2>Account Preferences</h2>
      <div className="settings-button settings-toggle">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
        </svg>
        <span>Dark Mode</span>
        <label className="settings-toggle-switch">
          <input
            type="checkbox"
            checked={darkMode}
            onChange={(e) => setDarkMode(e.target.checked)}
          />
          <span className="settings-toggle-slider"></span>
        </label>
      </div>

      <h2>Support</h2>
      <button
        className="settings-button"
        onClick={() => setActiveSection('faq')}
      >
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <circle cx="12" cy="12" r="10" />
          <path d="M9 9a3 3 0 1 1 6 0c0 2-3 3-3 3" />
          <circle cx="12" cy="17" r="0.5" />
        </svg>
        Help & FAQ
      </button>
      <button
        className="settings-button"
        onClick={() => setShowFeedbackModal(true)}
      >
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M21 11.5a8.38 8.38 0 0 0-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
        </svg>
        Share Feedback
      </button>
      <button 
        className="settings-button" 
        onClick={() => window.location.href = 'mailto:hi@kaiju.bio'}
      >
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <rect x="2" y="4" width="20" height="16" rx="2" ry="2" />
          <path d="M22 7l-10 7L2 7" />
        </svg>
        Contact Support
      </button>
    </div>
  );

  const renderContent = () => {
    if (activeSection === 'faq') {
      return <FAQ onBack={() => setActiveSection('settings')} />;
    }
    
    switch (activeSection) {
      case 'theme':
        return (
          <div className="theme-section">
            <h2>Choose Your Theme</h2>
            <div className="theme-grid">
              {themes.map(theme => (
                <div 
                  key={theme.id}
                  className={`theme-preview ${selectedTheme === theme.id ? 'selected' : ''}`}
                  onClick={() => setSelectedTheme(theme.id)}
                  style={{ backgroundColor: theme.color }}
                >
                  <div className="theme-preview-rectangles">
                    <div className="preview-rectangle" style={{ backgroundColor: theme.rectangleColor }}></div>
                    <div className="preview-rectangle" style={{ backgroundColor: theme.rectangleColor }}></div>
                    <div className="preview-rectangle" style={{ backgroundColor: theme.rectangleColor }}></div>
                  </div>
                  <span>{theme.name}</span>
                </div>
              ))}
            </div>
            <button 
              className="theme-save-button"
              onClick={async () => {
                setIsSavingTheme(true);
                try {
                  await updateDoc(doc(db, "users", auth.currentUser.uid), {
                    selectedTheme: selectedTheme
                  });
                  setShowSaveCheckmark(true);
                  setTimeout(() => setShowSaveCheckmark(false), 2000);
                } catch (error) {
                  console.error("Error saving theme:", error);
                  setError("Failed to save theme. Please try again.");
                } finally {
                  setIsSavingTheme(false);
                }
              }}
              disabled={isSavingTheme}
            >
              Save Theme
              <span className={`checkmark ${showSaveCheckmark ? 'visible' : ''}`}>✓</span>
            </button>
          </div>
        );

      case 'home':
        return (
          <>
            <div className="dashboard-profile-header">
              <div className="dashboard-profile-image-container" onClick={() => fileInputRef.current.click()}>
                <img
                  src={userProfile.profileImage || defaultProfile1}
                  alt="Profile"
                  className="dashboard-profile-image"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleProfileImageChange}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
              </div>

              <div className="dashboard-profile-info">
                <div className="dashboard-name-display">
                  {isEditingProfile ? (
                    <input
                      type="text"
                      value={editedDisplayName}
                      onChange={(e) => setEditedDisplayName(e.target.value)}
                      className="dashboard-name-input"
                      placeholder="Enter display name"
                      maxLength={30}
                    />
                  ) : (
                    <span>{userProfile.displayName || 'Add your name'}</span>
                  )}
                </div>

                {userProfile.bio || isEditingProfile ? (
                  <div className={isEditingProfile ? 'editing-bio' : 'dashboard-bio-display'}>
                    {isEditingProfile ? (
                      <textarea
                        value={editedBio}
                        onChange={(e) => setEditedBio(e.target.value)}
                        maxLength={BIO_MAX_LENGTH}
                        className="dashboard-bio-input"
                        placeholder="Add a bio..."
                      />
                    ) : (
                      <div 
                        onClick={() => {
                          setIsEditingProfile(true);
                          setEditedBio(userProfile.bio || '');
                        }}
                      >
                        {userProfile.bio || "Add a bio..."}
                      </div>
                    )}
                  </div>
                ) : null}

                {isEditingProfile && (
                  <div className="dashboard-profile-edit-buttons">
                    <button 
                      className="dashboard-profile-edit-button" 
                      onClick={() => setIsEditingProfile(false)}
                      disabled={isSaving}
                    >
                      Cancel
                    </button>
                    <button 
                      className={`dashboard-profile-edit-button save save-button ${isSaving ? 'loading' : ''}`}
                      onClick={handleSaveProfile}
                      disabled={isSaving}
                    >
                      Save
                    </button>
                  </div>
                )}
                {!isEditingProfile && (
                  <div className="dashboard-edit-profile-buttons">
                    <button 
                      className="dashboard-edit-profile-button"
                      onClick={handleEditProfileToggle}
                    >
                      Edit Profile
                    </button>
                    <button 
                      className="dashboard-edit-profile-button"
                      onClick={() => navigate('/add-social-media')}
                    >
                      Edit Socials
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="dashboard-form">
              {error && (
                <div className="dashboard-error-message">
                  {error}
                </div>
              )}
              {success && <div className="dashboard-success-message">{success}</div>}

              <div className="dashboard-creator-offers-section">
                <CreatorOffersSection
                  creatorOffers={creatorOffers}
                  suggestedPrices={suggestedPrices}
                  handleRemove={handleRemove}
                  handlePriceChange={handlePriceChange}
                  handleSelect={handleOfferSelect}
                  contentTypes={contentTypes}
                  moreContentTypes={moreContentTypes}
                  handleSaveChanges={handleSaveChanges}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  isSaving={isSaving}
                />
              </div>
            </div>
          </>
        );
      case 'orders':
        return (
          <OrdersList
            orders={orders}
            onAcceptOrder={handleAcceptOrder}
            onDeclineOrder={handleDeclineOrder}
            onDeclineClick={handleDeclineClick}
            onDeclineSubmit={handleDeclineSubmit}
            onCompleteOrder={handleCompleteOrder}
            stripeOnboardingComplete={userProfile.stripeOnboardingComplete}
            declineReason={declineReason}
            setDeclineReason={setDeclineReason}
            showFaq={showFaq}
            setShowFaq={setShowFaq}
          />
        );
      case 'payments':
        return (
          <PaymentMethodsSection
            sellerId={auth.currentUser?.uid}
            stripeOnboardingComplete={userProfile.stripeOnboardingComplete}
            stripeAccountId={userProfile.stripeAccountId}
          />
        );
      case 'purchases':
        return (
          <PurchasesSection
            purchases={sales}
          />
        );
      case 'settings':
        return renderSettingsSection();
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="dashboard-container">
        <div className="dashboard-loading-overlay">
          <ClipLoader color="#4CAF50" loading={isLoading} size={50} />
        </div>
      </div>
    );
  }

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : ''}`}>
      {showWelcomeModal && (
        <div className="welcome-modal">
          <div className="welcome-modal-content">
            <h2>Welcome to Kaiju! 🎉</h2>
            <div className="welcome-steps">
              <p>Here's how to get started:</p>
              <ol className="mt-3 space-y-2">
                <li>1. Click on "Add Service" to create your offers</li>
                <li>2. Set prices for your services, or leave them empty if you prefer to provide custom quotes</li>
                <li>3. When price is left empty, buyers will need to request a quote from you before proceeding</li>
              </ol>
            </div>
            <button 
              className="primary-button mt-4"
              onClick={() => {
                setShowWelcomeModal(false);
                // Scroll to the Add Service button
                const addButton = document.querySelector('.add-service-button');
                if (addButton) {
                  addButton.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Got it
            </button>
          </div>
        </div>
      )}
      <MobileNav 
        userProfile={userProfile} 
        totalNotifications={totalNotifications} 
      />
      
      <main className="dashboard-content">
        <div className="share-section">
          <button className="share-button" onClick={() => setShowShareMenu(!showShareMenu)}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" y1="2" x2="12" y2="15"></line>
            </svg>
            Share
          </button>
          <div className={`share-menu ${showShareMenu ? 'show' : ''}`}>
            <button className="share-menu-item" onClick={handleCopyUrl}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
              {copySuccess ? 'Copied!' : 'Copy URL'}
            </button>
            <button className="share-menu-item" onClick={handleOpenUrl}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line>
              </svg>
              Open in new tab
            </button>
            <button className="share-menu-item" onClick={handleShare}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
              Share...
            </button>
          </div>
        </div>
        {renderContent()}
      </main>
 
      <div className="preview-section">
        <div className="share-section">
          <button className="share-button" onClick={() => setShowShareMenu(!showShareMenu)}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" y1="2" x2="12" y2="15"></line>
            </svg>
            Share
          </button>
          <div className={`share-menu ${showShareMenu ? 'show' : ''}`}>
            <button className="share-menu-item" onClick={handleCopyUrl}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
              {copySuccess ? 'Copied!' : 'Copy URL'}
            </button>
            <button className="share-menu-item" onClick={handleOpenUrl}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line>
              </svg>
              Open in new tab
            </button>
            <button className="share-menu-item" onClick={handleShare}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
              Share...
            </button>
          </div>
        </div>
        <PreviewContainer 
          userProfile={{
            ...userProfile,
            selectedTheme: selectedTheme
          }}
          creatorOffers={creatorOffers}
          selectedTheme={selectedTheme}
        />
      </div>

      {/* Mobile Preview Button */}
      <button 
        className="mobile-preview-button"
        onClick={() => setIsPreviewOpen(true)}
        aria-label="Preview store"
      >
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
          <circle cx="12" cy="12" r="3" />
        </svg>
      </button>

      {/* Mobile Preview Panel */}
      <div className={`mobile-preview-panel ${isPreviewOpen ? 'open' : ''}`}>
        <div className="mobile-preview-header">
          <h2 className="mobile-preview-title">Store Preview</h2>
          <button 
            className="mobile-preview-close"
            onClick={() => setIsPreviewOpen(false)}
            aria-label="Close preview"
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <div className="mobile-preview-content">
          {userProfile.username ? (
            <iframe
              src={`/${userProfile.username}`}
              className="mobile-preview-frame"
              title="Store Preview"
              sandbox="allow-same-origin allow-scripts"
            />
          ) : (
            <div className="loading-message">
              Please set your username first to preview your store.
            </div>
          )}
        </div>
      </div>

      {showUsernameChangeModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Change Username</h3>
            <p>Enter your new username:</p>
            <input
              type="text"
              value={newUsername}
              onChange={(e) => {
                // Sanitize input to only allow lowercase letters, numbers, and hyphens
                const sanitizedUsername = e.target.value
                  .toLowerCase()
                  .replace(/[^a-z0-9-]/g, '');
                
                // Limit to 30 characters
                const limitedUsername = sanitizedUsername.slice(0, 30);
                
                setNewUsername(limitedUsername);
                
                // Clear error if user starts typing again
                if (error) setError(null);
              }}
              maxLength={30}
              placeholder="Enter new username"
              className={`username-input ${usernameAvailabilityMessage ? (isUsernameAvailable ? 'valid' : 'invalid') : ''}`}
              disabled={isSaving}
            />
            {usernameAvailabilityMessage && (
              <p className={`username-availability-message ${isUsernameAvailable ? 'success' : 'error'}`}>
                {usernameAvailabilityMessage}
              </p>
            )}
            <div className="username-change-warning">
              <div className="kaiju-url-preview">
                <span className="url-label">Your new URL will be:</span>
                <span className="url-link">
                  beta.kaiju.bio/{newUsername}
                </span>
              </div>
              <p className="username-change-limit">
                Username changes are limited to once every 14 days
              </p>
            </div>
            <div className="modal-actions">
              <button 
                className="modal-button cancel"
                onClick={() => {
                  setShowUsernameChangeModal(false);
                  setNewUsername('');
                }}
                disabled={isSaving}
              >
                Cancel
              </button>
              <button 
                className={`modal-button save btn-primary save-button ${isSaving ? 'loading' : ''}`}
                onClick={handleUsernameChangeRequest}
                disabled={!isUsernameAvailable || isSaving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        userId={auth.currentUser?.uid}
        userEmail={auth.currentUser?.email}
      />
      {showFirstOfferCelebration && (
        <FirstOfferCelebration onClose={() => setShowFirstOfferCelebration(false)} />
      )}
      <aside className={`dashboard-sidebar ${darkMode ? 'dark' : ''}`}>
        <nav className="dashboard-nav-menu">
          <div 
            className={`dashboard-nav-item ${activeSection === 'home' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=home')}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
              <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
            <span>Home</span>
          </div>

          <div 
            className={`dashboard-nav-item ${activeSection === 'theme' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=theme')}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" />
              <line x1="7" y1="7" x2="7.01" y2="7" />
            </svg>
            <span>Theme</span>
          </div>

          <div 
            className={`dashboard-nav-item ${activeSection === 'orders' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=orders')}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2" />
              <path d="M9 17h6M9 13h6M3 7h18" />
              <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2z" />
            </svg>
            <span>Orders</span>
            {totalNotifications > 0 && (
              <span className="notification-badge">{totalNotifications}</span>
            )}
          </div>

          <div 
            className={`dashboard-nav-item ${activeSection === 'payments' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=payments')}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <path d="M12 2v20M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6" />
            </svg>
            <span>Payment Setup</span>
          </div>

          <div 
            className={`dashboard-nav-item ${activeSection === 'purchases' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=purchases')}
          >
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none">
              <circle cx="9" cy="21" r="1" />
              <circle cx="20" cy="21" r="1" />
              <path d="M1 3h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6" />
            </svg>
            <span>Purchases</span>
          </div>

          <div 
            className={`dashboard-nav-item ${activeSection === 'settings' ? 'active' : ''}`}
            onClick={() => navigate('/dashboard?section=settings')}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="3" />
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
            </svg>
            <span>Settings</span>
          </div>

          {/* Desktop user profile */}
          <div className="dashboard-user-profile desktop-only user-profile-desktop" onClick={handleProfileClick}>
            <img 
              src={userProfile.profileImage || defaultProfile1} 
              alt="Profile" 
              className="dashboard-user-avatar"
            />
            <div className="dashboard-user-info">
              <p className="dashboard-user-username">{userProfile.displayName || userProfile.username}</p>
            </div>
            <div className={`profile-menu ${showLogoutMenu ? 'show' : ''}`}>
              <button onClick={handleSignOut} className="logout-button">
                <span>Logout</span>
                <span className="logout-icon">👋</span>
              </button>
            </div>
          </div>
        </nav>
      </aside>
      {showFaq && <FAQ onBack={() => setShowFaq(false)} />}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        userId={auth.currentUser?.uid}
        userEmail={auth.currentUser?.email}
      />
    </div>
  );
}

export default Dashboard;
