import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import './Auth.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      try {
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          createdAt: new Date(),
        });
        navigate('/claim-name');
      } catch (firestoreError) {
        console.error('Firestore error:', firestoreError);
        setError(`Failed to create user profile: ${firestoreError.message}`);
        if (user) {
          await user.delete();
          console.log('Auth user deleted due to Firestore write failure');
        }
      }
    } catch (error) {
      console.error('Signup error:', error);
      if (error.code === 'auth/weak-password') {
        setError("Password should be at least 6 characters long.");
      } else if (error.code === 'auth/email-already-in-use') {
        setError("This email is already registered. Please use a different email or try logging in.");
      } else if (error.code === 'auth/invalid-email') {
        setError("Please enter a valid email address.");
      } else if (error.code === 'auth/operation-not-allowed') {
        setError("Email/password sign up is not enabled. Please contact support.");
      } else {
        setError("An error occurred during signup. Please try again.");
      }
    }
  };

  return (
    <div className="auth-container">
      <button onClick={() => navigate('/')} className="back-button">← Back</button>
      <h1 className="auth-title">Claim your Kaiju account</h1>
      {error && <p className="auth-error">{error}</p>}
      <form onSubmit={handleSignup} className="auth-form">
        <div className="auth-input-group">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="auth-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="auth-input"
          />
        </div>
        <button type="submit" className="continue-button">Continue</button>      </form>
    </div>
  );
}

export default Signup;