import React from 'react';
import './PreviewContainer.css';
import BuyerView from '../BuyerView';

function PreviewContainer({ userProfile, creatorOffers, selectedTheme }) {
  const theme = selectedTheme || 'default';
  
  // Style object for profile blur theme
  const containerStyle = (theme === 'profile-blur-light' || theme === 'profile-blur-dark') && userProfile.profileImage
    ? { 
        backgroundImage: `url(${userProfile.profileImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    : {};
  
  return (
    <div className="theme-preview-panel" data-theme={theme}>
      <div className="theme-preview-phone-container">
        <div 
          className="theme-preview-phone" 
          data-theme={theme}
          style={(theme === 'profile-blur-light' || theme === 'profile-blur-dark') ? containerStyle : {}}
        >
          <div className="theme-preview-phone-content" data-theme={theme}>
            <BuyerView 
              isPreview={true}
              previewData={{
                ...userProfile,
                selectedTheme: theme,
                creatorOffers
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewContainer;
