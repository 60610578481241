// themes.js

// Helper functions
const isLightColor = (hexColor) => {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

const getTextColor = (backgroundColor) => {
  return isLightColor(backgroundColor) ? '#000000' : '#ffffff';
};

const getIconFilter = (backgroundColor) => {
  return isLightColor(backgroundColor) ? 'brightness(0)' : 'brightness(0) invert(1)';
};

// Theme definitions
export const themes = [
  // Essential Light/Dark
  { 
    id: 'light', 
    name: 'Light', 
    color: '#ffffff', 
    rectangleColor: '#f0f0f0',
    textColor: '#000000',
    iconColor: 'brightness(0)'
  },
  { 
    id: 'light-contrast', 
    name: 'Light Contrast', 
    color: '#ffffff', 
    rectangleColor: '#333333',
    textColor: '#000000',
    iconColor: 'brightness(0)'
  },
  { 
    id: 'dark', 
    name: 'Dark', 
    color: '#1a1a1a', 
    rectangleColor: '#2d2d2d',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  { 
    id: 'dark-contrast', 
    name: 'Dark Contrast', 
    color: '#1a1a1a', 
    rectangleColor: '#4a4a4a',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  
  // Nature Series
  {
    id: 'sage',
    name: 'Sage',
    color: '#D1DCCD',
    rectangleColor: '#C1CEB9',
    textColor: '#2C3B29',
    iconColor: 'brightness(0)'
  },
  {
    id: 'sage-contrast',
    name: 'Sage Contrast',
    color: '#D1DCCD',
    rectangleColor: '#2C3B29',
    textColor: '#2C3B29',
    iconColor: 'brightness(0)'
  },

  // Gradients
  { 
    id: 'sunset', 
    name: 'Sunset', 
    color: '#ff0844', 
    rectangleColor: '#ffb199',
    gradient: 'linear-gradient(135deg, #ff0844 0%, #ffb199 100%)',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  { 
    id: 'sunset-contrast', 
    name: 'Sunset Contrast', 
    color: '#ff0844', 
    rectangleColor: '#cc0636',
    gradient: 'linear-gradient(135deg, #ff0844 0%, #ffb199 100%)',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  { 
    id: 'bloom', 
    name: 'Bloom', 
    color: '#8f41e9', 
    rectangleColor: '#578aef',
    gradient: 'linear-gradient(135deg, #8f41e9 0%, #578aef 100%)',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  { 
    id: 'bloom-contrast', 
    name: 'Bloom Contrast', 
    color: '#8f41e9', 
    rectangleColor: '#3f6acf',
    gradient: 'linear-gradient(135deg, #8f41e9 0%, #578aef 100%)',
    textColor: '#ffffff',
    iconColor: 'brightness(0) invert(1)'
  },
  {
    id: 'skims',
    name: 'SKIMS',
    color: '#E6D1C6',
    rectangleColor: '#C4A99A',
    textColor: '#4A3F38',
    iconColor: 'brightness(0)',
    gradient: 'linear-gradient(135deg, #E6D1C6 0%, #C4A99A 100%)'
  },
  {
    id: 'skims-contrast',
    name: 'SKIMS Contrast',
    color: '#E6D1C6',
    rectangleColor: '#4A3F38',
    textColor: '#4A3F38',
    iconColor: 'brightness(0)',
    gradient: 'linear-gradient(135deg, #E6D1C6 0%, #C4A99A 100%)'
  }
];

// Add computed properties to themes that don't have them explicitly set
themes.forEach(theme => {
  if (!theme.textColor) {
    theme.textColor = getTextColor(theme.rectangleColor);
  }
  if (!theme.iconColor) {
    theme.iconColor = getIconFilter(theme.rectangleColor);
  }
});

export default themes;
