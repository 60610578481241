import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import confetti from 'canvas-confetti';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './OrdersList.css';
import LoadingOverlay from './LoadingOverlay';

const getDateInMillis = (order) => {
  if (order.orderDate?.toMillis) return order.orderDate.toMillis();
  if (order.orderDate?.seconds) return order.orderDate.seconds * 1000;
  if (order.createdAt?.toMillis) return order.createdAt.toMillis();
  if (order.createdAt?.seconds) return order.createdAt.seconds * 1000;
  if (order.timestamp?.toMillis) return order.timestamp.toMillis();
  if (order.timestamp?.seconds) return order.timestamp.seconds * 1000;
  return Date.now();
};

const OrdersList = ({ 
  orders, 
  onAcceptOrder, 
  onDeclineOrder,
  stripeOnboardingComplete,
  declineReason,
  setDeclineReason,
  onCompleteOrder,
  navigate
}) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [acceptingOrderId, setAcceptingOrderId] = useState(null);
  const [decliningOrderId, setDecliningOrderId] = useState(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [showAcceptGif, setShowAcceptGif] = useState(false);
  const [showDeclineGif, setShowDeclineGif] = useState(false);
  const [showFeedback, setShowFeedback] = useState('');
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [completingOrderId, setCompletingOrderId] = useState(null);
  const [showCompleteConfirm, setShowCompleteConfirm] = useState(false);
  const [showPriceQuoteModal, setShowPriceQuoteModal] = useState(false);
  const [quotePrices, setQuotePrices] = useState({});
  const [quoteDescriptions, setQuoteDescriptions] = useState({});
  const [completionMessage, setCompletionMessage] = useState('');
  const [showSetupPaymentModal, setShowSetupPaymentModal] = useState(false);

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  useEffect(() => {
    const newSortedOrders = [...orders].sort((a, b) => {
      const dateA = getDateInMillis(a);
      const dateB = getDateInMillis(b);
      return dateB - dateA;
    });
    setSortedOrders(newSortedOrders);
  }, [orders]);

  useEffect(() => {
    if (showFeedback) {
      const timer = setTimeout(() => {
        setShowFeedback('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showFeedback]);

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const handleModalClose = () => {
    setShowAcceptModal(false);
    setShowDeclineModal(false);
    setShowLoadingOverlay(false);
    setShowCompleteConfirm(false);
    setShowPriceQuoteModal(false);
    setQuotePrices({});
    setQuoteDescriptions({});
    setDeclineReason('');
    setShowFeedback('');
    setExpandedOrderId(null);
    setAcceptingOrderId(null);
    setDecliningOrderId(null);
    setCompletingOrderId(null);
    setCompletionMessage('');
    setShowSetupPaymentModal(false);
  };

  const handlePriceQuoteChange = (index, value) => {
    const newQuotePrices = { ...quotePrices };
    if (!newQuotePrices[selectedOrderId]) newQuotePrices[selectedOrderId] = {};
    const price = parseFloat(value);
    if (!isNaN(price)) {
      newQuotePrices[selectedOrderId][index] = price;
      setQuotePrices(newQuotePrices);
    }
  };

  const handleDescriptionChange = (index, value) => {
    const newQuoteDescriptions = { ...quoteDescriptions };
    if (!newQuoteDescriptions[selectedOrderId]) newQuoteDescriptions[selectedOrderId] = {};
    newQuoteDescriptions[selectedOrderId][index] = value;
    setQuoteDescriptions(newQuoteDescriptions);
  };

  const handleAcceptOrder = (order) => {
    if (!order.offers) {
      console.error('Order has no offers:', order);
      return;
    }

    if (!stripeOnboardingComplete) {
      setSelectedOrderId(order.id);
      setShowSetupPaymentModal(true);
      return;
    }

    setSelectedOrderId(order.id);
    console.log('Order:', order);
    
    const requiresQuote = order.offers.some(offer => {
      const offerNeedsQuote = 
        offer.requiresQuote || 
        offer.price === null || 
        offer.price === undefined ||
        (offer.customizations && offer.customizations.some(c => c.requiresQuote));
      console.log('Offer:', offer.name || offer.title, 'Needs quote:', offerNeedsQuote);
      return offerNeedsQuote;
    });

    console.log('Requires quote:', requiresQuote);

    if (requiresQuote) {
      setShowPriceQuoteModal(true);
      setShowAcceptModal(false);
    } else {
      setShowAcceptModal(true);
      setShowPriceQuoteModal(false);
    }
  };

  const handleAcceptOrderSubmit = async (orderId) => {
    if (acceptingOrderId) return;
    
    // Check Stripe onboarding status again before submitting
    if (!stripeOnboardingComplete) {
      setShowAcceptModal(false);
      setShowPriceQuoteModal(false);
      setSelectedOrderId(orderId);
      setShowSetupPaymentModal(true);
      return;
    }
    
    try {
      setAcceptingOrderId(orderId);
      setShowLoadingOverlay(true);
      setShowAcceptModal(false);

      const functions = getFunctions();
      const order = orders.find(o => o.id === orderId);
      const requiresQuote = order.offers.some(offer => 
        offer.requiresQuote || 
        offer.price === null || 
        offer.price === undefined ||
        (offer.customizations && offer.customizations.some(c => c.requiresQuote))
      );

      if (requiresQuote && quotePrices[orderId]) {
        // Handle price quote submission
        const submitPriceQuote = httpsCallable(functions, 'submitPriceQuote');
        
        // Prepare offers with quotes
        const offers = order.offers.map((offer, index) => {
          const price = quotePrices[orderId][index];
          if (typeof price !== 'number' || isNaN(price)) {
            throw new Error(`Invalid price for offer ${offer.name}: ${price}`);
          }
          return {
            ...offer,
            price: price,
            customDescription: quoteDescriptions[orderId]?.[index] || ''
          };
        });

        console.log('Submitting price quote with offers:', offers);
        const result = await submitPriceQuote({ 
          orderId,
          offers
        });

        if (result.data.success) {
          setShowLoadingOverlay(false);
          setShowFeedback('Price quote submitted successfully!');
          setShowAcceptGif(true);
          triggerConfetti();
          
          // Call onAcceptOrder to refresh the orders list
          await onAcceptOrder(orderId);
          
          setTimeout(() => {
            setShowAcceptGif(false);
            setShowFeedback('');
            handleModalClose();
          }, 3000);
        }
      } else {
        // Handle regular order acceptance
        const acceptRequest = httpsCallable(functions, 'acceptRequest');
        const result = await acceptRequest({ orderId });

        if (result.data.success) {
          setShowLoadingOverlay(false);
          setShowFeedback('Order accepted successfully!');
          setShowAcceptGif(true);
          triggerConfetti();
          
          // Call onAcceptOrder to refresh the orders list
          await onAcceptOrder(orderId);
          
          setTimeout(() => {
            setShowAcceptGif(false);
            setShowFeedback('');
            handleModalClose();
          }, 3000);
        }
      }
    } catch (error) {
      console.error('Error accepting order:', error);
      setShowLoadingOverlay(false);
      setShowFeedback('Error accepting order. Please try again.');
    } finally {
      setAcceptingOrderId(null);
    }
  };

  const handleDeclineOrder = async (orderId) => {
    if (decliningOrderId) return;
    
    try {
      setSelectedOrderId(orderId);
      setShowDeclineModal(true);
    } catch (error) {
      console.error('Error setting up decline:', error);
      setShowFeedback('Failed to set up decline. Please try again.');
    }
  };

  const handleDeclineSubmit = async () => {
    if (!selectedOrderId || !declineReason.trim()) {
      setShowFeedback("Please provide a reason for declining");
      return;
    }

    try {
      setDecliningOrderId(selectedOrderId);
      setShowLoadingOverlay(true);
      
      const functions = getFunctions();
      const declineRequest = httpsCallable(functions, 'declineRequest');
      const result = await declineRequest({ 
        orderId: selectedOrderId, 
        reason: declineReason.trim() 
      });

      if (result.data.success) {
        setShowLoadingOverlay(false);
        setShowDeclineModal(false);
        setShowDeclineGif(true);
        
        // Call onDeclineOrder to refresh the orders list
        await onDeclineOrder(selectedOrderId, declineReason);
        
        setTimeout(() => {
          setShowDeclineGif(false);
          handleModalClose();
        }, 3000);
      }
    } catch (error) {
      console.error('Error declining order:', error);
      setShowFeedback('Failed to decline order. Please try again.');
    } finally {
      setDecliningOrderId(null);
      setShowLoadingOverlay(false);
    }
  };

  const handleCompleteOrder = async (orderId) => {
    if (completingOrderId) return;
    
    try {
      setCompletingOrderId(orderId);
      setShowLoadingOverlay(true);
      setShowCompleteConfirm(false);

      const functions = getFunctions();
      const completeOrder = httpsCallable(functions, 'completeOrder');
      const result = await completeOrder({ 
        orderId,
        completionMessage: completionMessage.trim() 
      });

      if (result.data.success) {
        setShowFeedback('Order completed successfully!');
        setShowAcceptGif(true);
        triggerConfetti();
        
        // Call onCompleteOrder to refresh the orders list
        await onCompleteOrder(orderId);
        
        setTimeout(() => {
          setShowAcceptGif(false);
          handleModalClose();
        }, 3000);
      }
    } catch (error) {
      console.error('Error completing order:', error);
      setShowFeedback('Failed to complete order. Please try again.');
    } finally {
      setCompletingOrderId(null);
      setShowLoadingOverlay(false);
      setCompletionMessage('');
    }
  };

  const renderOrderActions = (order) => {
    const { status, payment_received } = order;

    if (status === 'pending') {
      return (
        <div className="status-actions-container">
          <div className="status-badge pending">Pending</div>
          <div className="action-buttons">
            <button
              className="action-button accept"
              onClick={(e) => {
                e.stopPropagation();
                handleAcceptOrder(order);
              }}
            >
              Accept
            </button>
            <button
              className="action-button decline"
              onClick={(e) => {
                e.stopPropagation();
                handleDeclineOrder(order.id);
              }}
            >
              Decline
            </button>
          </div>
        </div>
      );
    }

    if (status === 'accepted' && payment_received) {
      return (
        <button
          className="complete-button"
          onClick={(e) => {
            e.stopPropagation();
            handleCompleteOrder(order.id);
          }}
        >
          Mark as Complete
        </button>
      );
    }

    return (
      <div className={`status-badge ${status}`}>
        {status === 'accepted' && 'Accepted'}
        {status === 'completed' && 'Completed'}
        {status === 'declined' && 'Declined'}
        {status === 'cancelled' && 'Cancelled'}
        {status === 'payment_received' && 'Payment Received'}
        {!['accepted', 'completed', 'declined', 'cancelled', 'pending', 'payment_received'].includes(status) && 
          status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
    );
  };

  const renderCompletionModal = () => (
    <div className="modal-overlay" onClick={handleModalClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Complete Order</h3>
        <p>Add a message for the buyer (optional):</p>
        <textarea
          value={completionMessage}
          onChange={(e) => setCompletionMessage(e.target.value)}
          placeholder="Thank you for your order! I've completed everything as requested..."
          rows={4}
          style={{ width: '100%', marginBottom: '20px' }}
        />
        <div className="modal-actions">
          <button
            className="cancel-button"
            onClick={() => {
              setShowCompleteConfirm(false);
              setCompletionMessage('');
            }}
          >
            Cancel
          </button>
          <button
            className="accept-button"
            onClick={() => handleCompleteOrder(selectedOrderId)}
            disabled={completingOrderId === selectedOrderId}
          >
            {completingOrderId === selectedOrderId ? 'Completing...' : 'Complete Order'}
          </button>
        </div>
      </div>
    </div>
  );

  const renderPriceQuoteModal = () => {
    const order = orders.find(o => o.id === selectedOrderId);
    if (!order) return null;

    return (
      <div className="modal-overlay" onClick={handleModalClose}>
        <div className="modal-content price-quote-modal" onClick={e => e.stopPropagation()}>
          <h3>Provide Price Quote</h3>
          <p>Please provide a price quote for the following products:</p>
          <div className="price-quote-products">
            {order.offers.map((offer, index) => (
              <div key={index} className="price-quote-item">
                <h4>{offer.name || offer.title}</h4>
                <p>× {offer.quantity}</p>
                <input
                  type="number"
                  className="price-quote-input"
                  value={quotePrices[selectedOrderId]?.[index] || ''}
                  onChange={(e) => handlePriceQuoteChange(index, e.target.value)}
                  placeholder="Enter price..."
                  min="0"
                  step="0.01"
                />
                <textarea
                  className="quote-description-input"
                  value={quoteDescriptions[selectedOrderId]?.[index] || ''}
                  onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  placeholder="Add a note about this price (optional)..."
                />
              </div>
            ))}
          </div>
          <div className="modal-actions">
            <button
              className="action-button accept"
              onClick={() => setShowAcceptModal(true)}
              disabled={!Object.values(quotePrices[selectedOrderId] || {}).some(price => price > 0)}
            >
              Submit Quote
            </button>
            <button 
              className="cancel-button"
              onClick={handleModalClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderSetupPaymentModal = () => (
    <div className="modal-overlay" onClick={() => setShowSetupPaymentModal(false)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Setup Payment Required</h3>
        <p>Before you can accept orders, you need to set up how you want to get paid. This will allow you to receive payments from your customers securely.</p>
        <div className="modal-actions">
          <button 
            className="setup-button"
            onClick={() => {
              setShowSetupPaymentModal(false);
              window.location.href = '/dashboard?section=payments';
            }}
          >
            Setup Payment
          </button>
          <button 
            className="cancel-button"
            onClick={() => setShowSetupPaymentModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="orders-container">
      {showAcceptGif && (
        <div className="gif-overlay">
          <div className="feedback-container">
            <div className="feedback-message success">
              {showFeedback}
            </div>
            <img 
              src="https://media.giphy.com/media/OHs6JJvRA4v7hsxuzF/giphy.gif" 
              alt="Success" 
              className="success-gif" 
            />
          </div>
        </div>
      )}

      {showDeclineGif && (
        <div className="gif-overlay">
          <div className="feedback-container">
            <div className="feedback-message decline">
              {showFeedback}
            </div>
            <img 
              src="https://media.giphy.com/media/3o7TKwmnDgQb5jemjK/giphy.gif" 
              alt="Declined" 
              className="decline-gif" 
            />
          </div>
        </div>
      )}

      {!stripeOnboardingComplete && sortedOrders.length > 0 && (
        <div className="payment-setup-banner">
          <div className="banner-content">
            <p>💰 How would you like to get paid? Set up your payment details to start receiving orders!</p>
            <a href="/dashboard?section=payments" className="setup-button">
              Set Up Payments
            </a>
          </div>
        </div>
      )}

      {sortedOrders.length === 0 ? (
        <div className="no-orders">
          <p>No orders yet</p>
        </div>
      ) : (
        <>
          <div className="order-header">
            <div>Client</div>
            <div>Amount</div>
            <div>Date</div>
            <div>Status</div>
          </div>
          <div className="orders-list">
            {showLoadingOverlay && (
              <LoadingOverlay 
                message={`Please wait while we accept this order and notify ${orders.find(o => o.id === (acceptingOrderId || selectedOrderId))?.buyerName || 'the buyer'}`}
              />
            )}
            {sortedOrders.map((order) => (
              <React.Fragment key={order.id}>
                <div 
                  className={`order-item ${expandedOrderId === order.id ? 'expanded' : ''}`}
                  onClick={() => toggleOrderExpansion(order.id)}
                >
                  <div className="order-cell" data-label="Client">
                    {order.buyerName || 'Anonymous'}
                  </div>
                  <div className="order-cell" data-label="Amount">
                    ${typeof order.totalAmount === 'number' 
                      ? order.totalAmount.toFixed(2) 
                      : (order.amount || 0).toFixed(2)}
                  </div>
                  <div className="order-cell" data-label="Date">
                    {format(new Date(getDateInMillis(order)), 'MMM d')}
                  </div>
                  <div className="order-cell" data-label="Status">
                    {renderOrderActions(order)}
                  </div>
                </div>
                {expandedOrderId === order.id && (
                  <div className="order-details">
                    <div className="order-info">
                      <div className="order-info-grid">
                        <div className="order-info-section">
                          <h3>Order Information</h3>
                          <div className="info-group">
                            <label>Order ID</label>
                            <div>{order.id}</div>
                          </div>
                          <div className="info-group">
                            <label>Client Name</label>
                            <div>{order.buyerName || 'Anonymous'}</div>
                          </div>
                          <div className="info-group">
                            <label>Order Date</label>
                            <div>{format(new Date(getDateInMillis(order)), 'MMMM d, yyyy')}</div>
                          </div>
                          <div className="info-group">
                            <label>Total Amount</label>
                            <div className="amount">
                              ${typeof order.totalAmount === 'number' 
                                ? order.totalAmount.toFixed(2) 
                                : (order.amount || 0).toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className="order-info-section">
                          <h3>Products</h3>
                          <div className="products-grid">
                            {order.offers?.map((offer, index) => (
                              <div key={index} className="product-card">
                                <div className="product-name">{offer.name || offer.title}</div>
                                <div className="product-details">
                                  <span className="product-price">${offer.price?.toFixed(2) || '0.00'}</span>
                                  <span className="product-quantity">× {offer.quantity}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="order-info-section requirements-section">
                          <h3>Requirements</h3>
                          <div className="requirements-box">
                            {order.buyerRequirements || order.requirements || 'No specific requirements'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      {showPriceQuoteModal && renderPriceQuoteModal()}

      {showDeclineModal && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Decline Order</h3>
            <p>Please provide a reason for declining this order:</p>
            <textarea
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
              placeholder="Enter reason for declining..."
              className="decline-reason-input"
              rows={4}
              required
            />
            <div className="modal-actions">
              <button 
                className="action-button decline"
                onClick={handleDeclineSubmit}
                disabled={!declineReason.trim() || decliningOrderId}
              >
                {decliningOrderId ? 'Declining Order...' : 'Decline Order'}
              </button>
              <button 
                className="cancel-button"
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showAcceptModal && (
        <div className="modal-overlay" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Confirm Action</h3>
            <p>
              {quotePrices[selectedOrderId] ? (
                <>
                  Are you sure you want to submit this price quote?
                  <div className="quote-summary">
                    {orders.find(o => o.id === selectedOrderId)?.offers.map((offer, index) => {
                      const offerQuote = quotePrices[selectedOrderId]?.[index] || 0;

                      return offerQuote > 0 ? (
                        <div key={index} className="quote-item">
                          <span>{offer.name || offer.title}:</span>
                          <span>${offerQuote.toFixed(2)}</span>
                        </div>
                      ) : null;
                    })}
                  </div>
                </>
              ) : (
                'Are you sure you want to accept this order?'
              )}
            </p>
            <div className="modal-actions">
              <button 
                onClick={() => handleAcceptOrderSubmit(selectedOrderId)}
                className="action-button accept"
                disabled={acceptingOrderId === selectedOrderId}
              >
                {acceptingOrderId === selectedOrderId ? 
                  '⏳ Processing...' : 
                  quotePrices[selectedOrderId] ? '✅ Submit Quote' : '✅ Accept Order'
                }
              </button>
              <button 
                onClick={handleModalClose}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showCompleteConfirm && renderCompletionModal()}

      {showSetupPaymentModal && renderSetupPaymentModal()}
    </div>
  );
};

export default OrdersList;