import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import imageCompression from 'browser-image-compression';
import './FinishProfile.css';
import { auth, db, storage, functions } from '../firebase';
import defaultImage1 from '../assets/default-profile-1.png';
import defaultImage2 from '../assets/default-profile-2.png';

const defaultImages = [defaultImage1, defaultImage2];
const BIO_MAX_LENGTH = 100;
const DISPLAY_NAME_MAX_LENGTH = 50;

function FinishProfile() {
  const [selectedImage, setSelectedImage] = useState(defaultImage1);
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompressing, setIsCompressing] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Set default image on mount
    setSelectedImage(defaultImage1);
  }, []);

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setIsCompressing(true);
        
        // Compression options
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true
        };

        const compressedFile = await imageCompression(file, options);
        const user = auth.currentUser;
        const storageRef = ref(storage, `profile_pictures/${user.uid}`);
        await uploadBytes(storageRef, compressedFile);
        const downloadURL = await getDownloadURL(storageRef);
        setSelectedImage(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image. Please try again.");
        // If upload fails, keep the current selection or fall back to defaultImage1
        if (!selectedImage) {
          setSelectedImage(defaultImage1);
        }
      } finally {
        setIsCompressing(false);
      }
    }
  };

  const handleBioChange = (e) => {
    const text = e.target.value;
    if (text.length <= BIO_MAX_LENGTH) {
      setBio(text);
    }
  };

  const handleDisplayNameChange = (e) => {
    const text = e.target.value;
    if (text.length <= DISPLAY_NAME_MAX_LENGTH) {
      setDisplayName(text);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (user) {
      try {
        setIsLoading(true);
        setError(null);

        // Use the first default image if no image is selected
        const profileImage = selectedImage || defaultImage1;
        
        // Use username if no display name is provided
        const finalDisplayName = displayName.trim() || user.email.split('@')[0];

        // Update the profile with optional fields
        await updateDoc(doc(db, "users", user.uid), {
          displayName: finalDisplayName,
          bio: bio || '', // Empty string if no bio
          profileImage,
          lastUpdated: new Date()
        });

        try {
          // Send welcome email - wrapped in try/catch to prevent blocking completion
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.data();
          const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
          await sendWelcomeEmail({ 
            email: user.email,
            profile: {
              username: userData.username,
              displayName: finalDisplayName,
              bio: bio || '',
            }
          }).catch(console.error);
        } catch (emailError) {
          console.error("Error sending welcome email:", emailError);
          // Continue even if email fails
        }

        // Navigate to dashboard
        navigate('/dashboard');
      } catch (error) {
        console.error("Error updating profile:", error);
        setError("Failed to complete profile setup. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="finish-profile-container">
      <h1 className="finish-profile-title">Finish your profile</h1>
      <p className="finish-profile-subtitle">This is the last step. Promise!</p>

      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit} className="profile-form">
        <div className="profile-image-selection">
          {defaultImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Default profile ${index + 1}`}
              className={`profile-image-option ${selectedImage === image ? 'selected' : ''}`}
              onClick={() => handleImageSelect(image)}
            />
          ))}
          <label className="upload-image-button">
            <input 
              type="file" 
              accept="image/*" 
              onChange={handleImageUpload} 
              disabled={isCompressing}
              ref={fileInputRef}
            />
            {isCompressing ? (
              <div className="compression-loader">Compressing...</div>
            ) : selectedImage && !defaultImages.includes(selectedImage) ? (
              <img src={selectedImage} alt="Uploaded profile" className="uploaded-image-preview" />
            ) : (
              '+'
            )}
          </label>
        </div>

        <div className="input-container">
          <input
            type="text"
            placeholder="Display name"
            value={displayName}
            onChange={handleDisplayNameChange}
            className="profile-input"
            maxLength={DISPLAY_NAME_MAX_LENGTH}
          />
        </div>

        <div className="bio-container">
          <textarea
            placeholder="Bio"
            value={bio}
            onChange={handleBioChange}
            className="profile-input bio-input"
            maxLength={BIO_MAX_LENGTH}
          />
        </div>

        <button 
          type="submit" 
          className="kaiju-continue-button"
          disabled={isLoading || isCompressing}
        >
          {isLoading ? 'Saving...' : 'Complete Profile'}
        </button>
      </form>
    </div>
  );
}

export default FinishProfile;