import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import FeedbackDashboard from './FeedbackDashboard';
import PaymentVotesDashboard from './PaymentVotesDashboard';
import './Admin.css';

// List of admin emails
const ADMIN_EMAILS = ['hi@kaiju.bio', 'guillermogar@gmail.com', 'kilmaruteam@gmail.com', 'kilmarubiz@gmail.com','martawithat@gmail.com'];

const Admin = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState('feedback');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user || !ADMIN_EMAILS.includes(user.email)) {
        navigate('/');
        return;
      }
      setIsAdmin(true);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!isAdmin) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-container">
      <div className="admin-sidebar">
        <h2>Admin Panel</h2>
        <nav>
          <button
            className={`nav-button ${activeTab === 'feedback' ? 'active' : ''}`}
            onClick={() => setActiveTab('feedback')}
          >
            Feedback
          </button>
          <button
            className={`nav-button ${activeTab === 'payment-votes' ? 'active' : ''}`}
            onClick={() => setActiveTab('payment-votes')}
          >
            Payment Methods
          </button>
        </nav>
      </div>
      
      <div className="admin-content">
        {activeTab === 'feedback' && <FeedbackDashboard />}
        {activeTab === 'payment-votes' && <PaymentVotesDashboard />}
      </div>
    </div>
  );
};

export default Admin;
