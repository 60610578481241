// SellerConnectButton.js
import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase';
import './SellerConnectButton.css';

function SellerConnectButton({ sellerId, stripeOnboardingComplete, stripeAccountId, className }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleStripeAction = async () => {
    if (!sellerId) return;
    setIsLoading(true);
    setError(null);
    
    try {
      const createStripeAccount = httpsCallable(functions, 'createStripeAccount');
      const result = await createStripeAccount();
      if (result.data?.url) {
        window.location.href = result.data.url;
      }
    } catch (err) {
      console.error('Stripe connection error:', err);
      // Extract the error message from Firebase Functions error
      const errorMessage = err.message?.includes('platform\'s Stripe account') 
        ? err.message 
        : 'Unable to connect to Stripe. Please try again later.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonText = () => {
    if (isLoading) return 'Connecting...';
    if (stripeAccountId) {
      return stripeOnboardingComplete ? 'Manage payment account' : 'Complete setup';
    }
    return 'Set up your payment account';
  };

  const getButtonClassName = () => {
    if (stripeOnboardingComplete) {
      return 'stripe-action-btn stripe-action-btn-configured';
    }
    return 'stripe-action-btn stripe-action-btn-unconfigured';
  };

  return (
    <div className="stripe-connection-status">
      <button 
        onClick={handleStripeAction} 
        disabled={isLoading} 
        className={`${getButtonClassName()} ${className || ''}`}
      >
        {getButtonText()}
      </button>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}

export default SellerConnectButton;